import React from 'react'

export default function Spotlight(props) {
    return (
        <div className="spotlight articleOnly" style={{backgroundImage: `url(${props.bgImg})`, minHeight:`${props.height}vh`, backgroundColor: `${props.bgColor}`}}>
            <div className="content">
                <span>{props.tags}</span>
                <h5>{props.title}</h5>
            </div>
        </div>
    )
}
