import React from "react"
import { graphql, Link } from "gatsby"
import {Row, Container} from "react-bootstrap"
import Masonry from 'react-masonry-css'


import Layout from "../components/layout"
import SEO from "../components/seo"
import SpotlightArticle from "../components/SpotlightArticle"
import thumbnail1 from "../media/img/development/dev-thumb1.png"

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  700: 2,
  500: 1
};

export default function work(props) {
    return (
        <Layout pageInfo={{ pageName: "Work" }}>
        <SEO title="Work" />
        <SpotlightArticle tags={`All Work`} title={` sed do eiusmod tempor incididunt ut labore et dolore magna aliqua`} />
        <section>
        <Container>
            <Row>
            <div className="sorting--parent">
                <small><span>{props.data.allIntranetPost.edges.length}</span> Articles</small>
            </div>
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="news--parent"
                columnClassName="news--parent--column"
            >
                {props.data.allIntranetPost.edges.map(work =>{
                    return (
                        <div className="news--content" key={work.node.id}>
                            <Link to={`/work/${work.node.id__normalized}`}>
                                <div className="image">
                                    <img src={work.node.image !== `` ? work.node.image :  thumbnail1} alt="" />
                                </div>
                                <small>
                                    Tag
                                </small>
                                <div className="excerpt">
                                    {work.node.excerpt}
                                </div>
                                <small>View</small>
                            </Link>
                        </div>
                    )
                })}
                
            </Masonry>
            </Row>

        </Container>
        </section>
    </Layout>
    )
}
export const query = graphql`
    query workList {
        allIntranetPost(filter: {category: {eq: "Work"}}) {
            edges {
                node {
                    title
                    image
                    excerpt
                    id
                    id__normalized
                    category
                }
            }
        }
    }
`